.loanProgramDiv {
  margin-top: 65px;
  margin-bottom: 45px;
  line-height: 1.6em;
  padding-top: 30px;
}

.loanProgramContentDiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/loanpurposeimagebackground.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.loanhowitworksDiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/howitworksbgimage.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.loanresourcesDiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/loanprogramsdot3.webp');
  background-repeat: no-repeat;
  background-size: 5% 25%;
  background-position: left 66%;
  position: relative;
}

.loanresourcesContainer .card-title {
  font-size: 1rem !important;
}

.loanProgramContainer {
  width: 950px;
}

.loanhowitworksContainer {
  width: 1100px;
}

.loanresourcesContainer {
  width: 1200px;
}

.applynowbanner {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10rem;
  margin-bottom: 5rem;
  width: 30%;
}

.loanpurposeimage {
  max-width: 100%;
  margin-bottom: 2rem;
}

.loanprogrambannerimage {
  width: 100%;
  margin-top: -2rem;
}

.loanpurposeimagebackground {
  max-width: 100%;
}

.loanProgramDiv a {
  text-decoration: none;
}

.setNavLeft {
  flex-direction: column;
  text-align: center;
}

.setNavLeft a {
  cursor: pointer;
}

.leftHeader {
  font-size: 40px;
  font-family: "Poppins";
  margin-bottom: 25px;
  color: #00AAE4;
  font-weight: 800;
  text-align: center;
  margin-top: 15px;
}

.fixedLeft {
  margin: 2rem 0 1.5rem;
  /* margin: 4rem 0; */
  box-sizing: border-box;
  box-shadow: 0 0 30px 0px #00AAE4;
  border-radius: 35px;
  padding: 0px 15px;
  /* padding: 15px; */
}

.rightCol {
  /* margin-left:30%; */
}

.activePillLink {
  font-size: 15px;
  font-family: "Graphik Medium";
  transition: cubic-bezier(0.19, 1, 0.22, 1);
  color: #3074b7;
}

.pillLink {
  text-decoration: none;
  border-bottom: 2px solid #C4C4C4;
}

.pillLink1 {
  text-decoration: none;
  border-bottom: none;
}

.pillLink2 {
  text-decoration: none;
  border-bottom: none;
}

.loanProgramDiv .nav-pills .nav-link {
  border-radius: 0 !important;
}



.bannerButton {
  font-weight: 100;
  font-family: "Graphik Medium";
  letter-spacing: 0.3px;
  background-color: #3074b7;
  color: #fff;
  padding: 14px 33px !important;
  border-radius: 30px !important;
  border: 2px solid #3074b7 !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all .3s;
  color: #ffffff;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.whiteBannerButton {
  background-color: white !important;
  color: #3074b7 !important;
}

.loanHeader {
  margin: 4rem 0 2rem;
  text-align: left;
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  font-family: "Poppins";
}

.conventional h2 {

  color: #3074b7;
  font-size: 30px;
  font-weight: 900 !important;
  font-family: "Poppins";
  font-weight: 100;
  margin: 0;
  margin-bottom: 25px;
  margin-top: 30px;
}

.conventional p {
  font-size: 21px;
  font-family: "Poppins";
  color: #083F88;
  font-weight: 500;
  margin-bottom: 3rem;
}

.conventional a {
  color: #083F88;
}

.conventional li {
  font-size: 20px;
  font-family: "Poppins";
  color: #083F88;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.conventional li:last-child {
  margin-bottom: 3rem;
}

.conventional h3 {
  font-size: 29px;
  font-family: "Poppins";
  font-weight: 900;
  margin-bottom: 2rem;
  color: #083F88;
}
.dscr_discalimers{
  color: #615555;
  font-family: "Poppins";
  font-weight: 400;
  padding: 0 11rem;
}

@media(min-width:600px)and (max-width:1025px) {
  .servicesCard {
    display: inline-table;
  }

  /*  */
}

@media(min-width: 1200px)and (max-width:1399.8px) {
  .applynowbanner {
    margin-top: -9rem;
    width: 25%;
  }
  .dscr_discalimers{
    padding: 0 6rem;
  }
}

@media(min-width: 992px)and (max-width:1199.7px) {
  .loanProgramContainer {
    width: 750px !important;
  }

  .loanHeader {
    font-size: 40px;
  }

  .loanhowitworksContainer {
    width: 900px;
  }
  .dscr_discalimers{
    padding: 0 6rem;
  }

  .applynowbanner {
    margin-top: -7rem;
    margin-bottom: 4rem;
    width: 25%;
  }

  .loanProgramContentDiv {
    background-size: 100% 80% !important;
  }

  .loanhowitworksDiv {
    background-image: none;
    background-color: #00AAE4;
  }

  .loanresourcesDiv {
    background-image: none;
  }
}

@media(min-width: 768px)and (max-width:991.7px) {
  .loanProgramContainer {
    width: 620px !important;
  }

  .dscr_discalimers{
    padding: 0 3rem;
  }
  .loanHeader {
    font-size: 32px;
  }

  .loanhowitworksContainer {
    width: 900px;
  }

  .applynowbanner {
    margin-top: -5rem;
    margin-bottom: 3rem;
    width: 20%;
  }

  .loanProgramContentDiv {
    background-image: url('https://staging-cdn.swmc.com/lowrates/loanpurposeimagebackgroundmobile.webp');
    background-repeat: no-repeat;
    background-size: 100% 15%;
    position: relative;
  }

  .loanhowitworksDiv {
    background-image: none;
    background-color: #00AAE4;
  }

  .loanresourcesDiv {
    background-image: none;
  }

  .conventional h3 {
    text-align: justify;
  }

  .conventional p {
    text-align: justify;
    margin-bottom: 2rem;
  }

  .conventional li {
    text-align: justify;
  }

  .loanprogrambannerimage {
    margin-top: -1rem;
  }
}

@media(min-width:768px) {
  .loanprogrambannerimagemobile {
    display: none;
  }
}

@media(max-width:767.7px) {
  .rightCol {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 35%;
  }

  .dscr_discalimers{
    padding: 0 0rem;
  }
  .fixedLeft {
    height: auto;
    background-color: #FCFCFC;
    padding: 15px !important;
      margin: 4rem 0 3rem !important;
  }

  .setNavLeft {
    flex-wrap: nowrap !important;
    overflow: scroll;
  }

  .loanProgramDiv {
    margin-top: 30px !important;
  }

  .loanhowitworksDiv {
    background-image: none !important;
    background-color: #00AAE4 !important;
  }

  .applynowbanner {
    width: 75% !important;
    margin-top: -8rem !important;
    margin-bottom: 2rem !important;
  }

  .loanhowitworksContainer {
    width: auto !important;
  }

  .loanresourcesContainer {
    width: 95% !important;
  }

  .loanProgramContainer {
    width: auto !important;
    margin-bottom: -1rem;
  }

  .fixedLeft {
    box-shadow: 0 0 20px 0px #00AAE4;
    border-radius: 0px !important;
    padding: 15px;
    
  }

  .leftHeader {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .pillLink1 {
    text-decoration: underline;
    border-bottom: 2px solid #C4C4C4;
  }

  .loanprogrambannerimage {
    display: none;
  }

  .loanprogrambannerimagemobile {
    width: 100%;
  }

  .loanProgramContentDiv {
    background-image: url('https://staging-cdn.swmc.com/lowrates/loanpurposeimagebackgroundmobile.webp');
    background-repeat: no-repeat;
    background-size: 100% 10%;
    position: relative;
  }

  .loanHeader {
    margin-top: -5rem;
    font-size: 25px;
    font-weight: 900;
    text-align: center;
  }

  .loanpurposeimage {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }

  .conventional h3 {
    font-size: 20px;
    text-align: justify;
  }

  .conventional p {
    font-size: 16px;
    text-align: justify;
    margin-bottom: 2rem;
  }

  .conventional li {
    font-size: 16px;
    text-align: justify;
  }

  .loanresourcesDiv {
    background-image: none;
  }

  .loanpurposemortgageprocessimg {
    width: 100%;
    margin-bottom: 2rem;
  }

}


.loanpurposemortgageprocessimg {
  width: 100%;
  margin-bottom: 2rem;
}



.pillLink,
.pillLink1,
.pillLink2 {
  display: block;
  font-size: 20.1px;
  font-weight: 800;
  padding: 0.8rem 1rem;
  color: #083F88;
  border-radius: 0 !important;
}


@media(min-width: 1200px)and (max-width:1400px) {

  .pillLink,
  .pillLink1,
  .pillLink2 {
    font-size: 18.1px;
  }
}

@media(min-width: 992px)and (max-width:1199.7px) {

  .pillLink,
  .pillLink1,
  .pillLink2 {
    font-size: 15.1px;
    font-weight: 900;
  }
}

@media(min-width: 768px)and (max-width:991.7px) {

  .pillLink,
  .pillLink1,
  .pillLink2 {
    font-size: 12.7px;
    padding: 0.5rem 0.5rem;
    font-weight: 900;
  }
}

@media(max-width:767px) {

  .pillLink,
  .pillLink1,
  .pillLink2 {
    font-size: 15.5px;
    padding: 0.5rem 0.5rem;
  }
}